import React from 'react';
import get from 'lodash/get';

import Layout from '../components/Layout';
import Yearly from '../components/Yearly';
import Monthly from '../components/Monthly';

const EcogymPage = ({ data }) => {
  const hero = get(data, 'hero.childImageSharp.fixed.src');
  const ecoLogo = get(data, 'ecoLogo.childImageSharp.fixed.src');
  const mealPlans = get(data, 'mealPlans.childImageSharp.fixed.src');
  const jessi = get(data, 'jessi.childImageSharp.fixed.src');
  const leftQuote = get(data, 'leftQuote.childImageSharp.fixed.src');
  const rightQuote = get(data, 'rightQuote.childImageSharp.fixed.src');

  // console.log('hero', hero);

  return (
    <Layout>
      <div
        className="relative"
        style={{
          backgroundImage: `url(${hero})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top right -12rem',
        }}
      >
        <div className="absolute pin bg-white lg:bg-transparent z-0 opacity-90" />

        <div className="relative container md:mx-auto px-4 md:px-0 py-4 z-10">
          <img className="w-32" src={ecoLogo} alt="Eco Gym" />
        </div>
        <div className="relative container mx-auto py-16 md:py-32 z-10">
          <div className="lg:w-1/2 px-4 md:px-0">
            <div className="inline-block">
              <h2 className="text-grey-darker mb-2 font-normal text-2xl antialiased">
                EcoGym Presents
              </h2>
              <h1 className="text-green-dark mb-1 font-normal antialiased">
                The Balanced Life Program
              </h1>
              <div className="text-grey-dark mb-8 text-right font-sans uppercase text-sm tracking-wide">
                by Jessi McLennan
              </div>
            </div>
            <p>
              At Eco Gym, we understand that when it comes to health and
              fitness, exercise is only part of the puzzle.
            </p>

            <p>
              Diet and lifestyle plays an equally important role in helping you
              achieve your goals. Many studies say that 80% of your success is
              going to come down to the nutrition habits you practice at home.
            </p>

            <p>
              That’s why we’ve created an exclusive online nutrition program,
              developed by our registered nutritional therapist, to provide you
              with all the tools and knowledge that you need to reach your goals
              and perform at your best.
            </p>

            <p>Member have instant access to:</p>

            <ul className="font-sans text-grey-darker">
              <li className="mb-2">Streaming video</li>

              <li className="mb-2">Downloadable worksheets and activities</li>

              <li className="mb-2">Meal plans</li>

              <li className="mb-2">Shopping list</li>

              <li className="mb-2">
                Monthly recipe books and new monthly content
              </li>

              <li className="mb-2 leading-normal">
                Our private facebook community where we host live Q&A’s and
                create a space for accountability and continued success.
              </li>
            </ul>

            <a href="#pricing" className="btn btn-main mt-6 inline-block">
              Join The Balanced Life Program
            </a>
          </div>
        </div>
      </div>

      <div
        className="py-24 mb-24"
        style={{
          backgroundImage: `url(${mealPlans})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="container mx-auto md:flex">
          <a name="pricing" />
          <div className="hidden lg:block md:w-1/3">&nbsp;</div>
          <div className="lg:w-2/3 mx-4 md:mx-0 md:pl-12">
            <h3 className="text-center mb-8 text-3xl text-grey-dark font-normal">
              Plans &amp; Pricing
            </h3>

            <div className="md:flex">
              <Yearly />
              <Monthly />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto md:flex">
        <div className="md:w-1/2 mx-4 md:mx-0">
          <h3 className="mb-8 text-3xl text-grey-dark font-normal">
            About Jessi McLennan
          </h3>
          <p>
            Jessi McLennan is a Registered Nutritional Therapist specializing in
            diet and lifestyle planning, specifically targeting individuals
            committed to weight loss, improving athletic performance and
            improving overall health and wellness.
          </p>

          <p>
            Over the past 10 years Jessi has studied and worked in the field of
            health and nutrition. Her expertise in holistic nutrition and
            passion for vibrant health led to her current position at the Chopra
            Treatment Center – A private treatment center affiliated with the
            Deepak Chopra Center in Los Angeles. For the last 7 years Jessi has
            run weekly nutrition workshops and provides individual nutrition
            counselling helping 100’s of guest to improve their health and
            quality of life. She teaches practical skills such as meal planning
            and cooking with whole-foods while providing education on the
            transformative effect that food can play on health.
          </p>

          <p>
            In between presenting workshops and working with private clients,
            Jessi is the team nutritionist for Zep Techniques Race Team - an
            elite development squad for down-hill mountain biking, based in
            Whistler Canada. She works with athlete’s to individualize
            macro-nutrient requirements and creates plans to enhance endurance
            and speed recovery.
          </p>

          <p>
            One of Jessi’s strengths is creating easy-to-follow protocols which
            produce lasting results. This is achieved by challenging the
            mass-market restriction and deprivation type diets and instead
            developing programs tailored to suit individual’s food preferences
            and lifestyle. Her programs support clients with specific goals from
            weight management to improving physical health and mental wellbeing.
          </p>
        </div>

        <div className="md:w-1/2 mx-4 md:mx-0 md:ml-16">
          <img
            src={jessi}
            alt="Jessi McLennan"
            className="rounded shadow-md"
            className="mb-4"
          />

          <div
            className="text-xl text-grey-dark leading-loose text-center p-6"
            style={{
              backgroundImage: `url(${leftQuote}), url(${rightQuote})`,
              backgroundPosition: `10px 20px, right 30px bottom`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            I believe that eating well is an integral part of good health. When
            you eat better, you feel better and when you feel better your
            potential is limitless. I look forward to working with you and
            helping you to achieve your potential.
          </div>
        </div>
      </div>

      <div className="mt-32 mb-16 text-sm text-grey-dark text-center">
        &copy; Copyright {new Date().getFullYear()} - All Rights Reserved.
      </div>
    </Layout>
  );
};

export default EcogymPage;

export const pageQuery = graphql`
  query {
    hero: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "hero-gradient.png" }
    ) {
      childImageSharp {
        fixed(width: 1440, quality: 100, toFormat: JPG) {
          src
        }
      }
    }
    mealPlans: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "springMeal.jpg" }
    ) {
      childImageSharp {
        fixed(width: 1200, quality: 80, toFormat: JPG) {
          src
        }
      }
    }
    ecoLogo: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "eco_gym_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 400, toFormat: PNG) {
          src
        }
      }
    }
    jessi: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "jessi.jpg" }
    ) {
      childImageSharp {
        fixed(width: 600, quality: 60, toFormat: JPG) {
          src
        }
      }
    }
    leftQuote: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "leftQuote.png" }
    ) {
      childImageSharp {
        fixed(width: 60) {
          src
        }
      }
    }
    rightQuote: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { glob: "rightQuote.png" }
    ) {
      childImageSharp {
        fixed(width: 60) {
          src
        }
      }
    }
  }
`;
