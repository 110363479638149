import React from 'react';

const Monthly = () => (
  <div className="flex-1 m-2 font-sans">
    <div className="relative bg-white rounded shadow p-16 text-center overflow-hidden border-4 border-white hover:border-blue-lighter">
      <div className="text-grey-dark text-4xl mb-4 font-serif border border-t-0 border-l-0 border-r-0 border-b-2 border-grey-lighter pb-4">
        Monthly
      </div>
      <div className="text-grey uppercase text-sm mb-1">Regular Price:</div>
      <div
        style={{ textDecoration: 'line-through' }}
        className="text-red-light mb-6"
      >
        $19.99
      </div>
      <div className="flex mb-2 justify-center">
        <div className="text-4xl">$7</div>
        <div className="text-md mt-1">.99</div>
      </div>
      <div className="mb-8 text-sm uppercase text-grey">per month</div>

      <a
        href="https://thebalancedlifeprogram.thinkific.com/enroll/392662?price_id=408834"
        className="btn btn-main block"
      >
        Join Monthly
      </a>
    </div>
  </div>
);

export default Monthly;
