import React from 'react';

const Yearly = () => (
  <div className="flex-1 m-2 mb-4 font-sans">
    <div className="relative bg-white rounded shadow-lg p-16 text-center overflow-hidden border-4 border-blue-lighter hover:border-blue-light">
      <div
        className="absolute pin-t pin-r text-xs bg-green text-white uppercase tracking-wide py-1 px-8 antialiased shadow"
        style={{
          transform: 'rotate(45deg)',
          marginRight: -40,
          marginTop: 30,
        }}
      >
        Most Popular
      </div>
      <div className="text-grey-dark text-4xl mb-4 font-serif border border-t-0 border-l-0 border-r-0 border-b-2 border-grey-lighter pb-4">
        Yearly
      </div>
      <div className="text-grey uppercase text-sm mb-1">Regular Price:</div>
      <div
        style={{ textDecoration: 'line-through' }}
        className="text-red-light mb-6"
      >
        $240.00
      </div>
      <div className="flex mb-2 justify-center">
        <div className="text-4xl">$47</div>
        <div className="text-md mt-1">.99</div>
      </div>
      <div className="mb-8 text-sm uppercase text-grey">per year</div>

      <a
        href="https://thebalancedlifeprogram.thinkific.com/enroll/334620?price_id=346230"
        className="btn btn-main block"
      >
        Join Yearly
      </a>
    </div>
  </div>
);

export default Yearly;
